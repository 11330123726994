import { atom } from "recoil";
import { UserInfo } from "./utils/jwtUtils";

export const isLoggedInAtom = atom<boolean>({
  key: "isLoggedIn",
  default: false,
});

export const userInfoAtom = atom<UserInfo | null>({
  key: "userInfo",
  default: null,
});

// Add this to your existing atoms
export const isPasscodeAuthenticatedAtom = atom({
  key: "isPasscodeAuthenticated",
  default: false,
});
