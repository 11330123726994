import { ReactNode, useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";
import { isPasscodeAuthenticatedAtom } from "../../atoms";

interface ProtectedRouteProps {
  children: ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const [isPasscodeAuthenticated, setIsPasscodeAuthenticated] = useRecoilState(
    isPasscodeAuthenticatedAtom
  );
  const location = useLocation();

  useEffect(() => {
    // Check localStorage on component mount
    const isAuthenticated =
      localStorage.getItem("demo_authenticated") === "true";
    setIsPasscodeAuthenticated(isAuthenticated);
  }, [setIsPasscodeAuthenticated]);

  if (!isPasscodeAuthenticated) {
    // Redirect to passcode page, but save the location they were trying to access
    return <Navigate to="/passcode" state={{ from: location }} replace />;
  }

  return <>{children}</>;
};

export default ProtectedRoute;
