import { Box, Title } from "@mantine/core";
import MdDisplay from "../../components/MdDisplay/MdDisplay";
import { privacyPolicy } from "../../constants/text/privacyPolicy";

function PrivacyPolicyPage() {
  return (
    <Box
      px={{ base: "10vw", md: "20vw", lg: "25vw" }}
      py={{ base: 10, md: 50 }}
    >
      <Title mb={4} order={1}>
        Privacy Policy for affiliateformula.io
      </Title>
      <MdDisplay content={privacyPolicy} />
    </Box>
  );
}

export default PrivacyPolicyPage;
