import { Box, Title } from "@mantine/core";
import MdDisplay from "../../components/MdDisplay/MdDisplay";
import { termsOfService } from "../../constants/text/termsOfService";

function TermsOfServicePage() {
  return (
    <Box
      px={{ base: "10vw", md: "20vw", lg: "25vw" }}
      py={{ base: 10, md: 50 }}
    >
      <Title mb={4} order={1}>
        Terms of Service for affiliateformula.io
      </Title>
      <MdDisplay content={termsOfService} />
    </Box>
  );
}

export default TermsOfServicePage;
