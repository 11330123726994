import { environment } from "../../constants/environment";

function LoginPage() {
  return (
    <>
      <a href={`${environment.BACKEND_URL}/tiktok/login`}>
        Continue with TikTok
      </a>
    </>
  );
}

export default LoginPage;
