import {
  Box,
  Button,
  Container,
  Image,
  Paper,
  Stack,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import Logo_dark from "../../assets/full_logo2.svg"; // Adjust path as needed
import { isPasscodeAuthenticatedAtom } from "../../atoms";
import { environment } from "../../constants/environment";

const DEMO_PASSCODE = environment.PASSCODE; // Store this in environment variables in a real app

const PasscodePage: React.FC = () => {
  const [passcode, setPasscode] = useState("");
  const [error, setError] = useState("");
  const setIsPasscodeAuthenticated = useSetRecoilState(
    isPasscodeAuthenticatedAtom
  );
  const navigate = useNavigate();

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (passcode === DEMO_PASSCODE) {
      // Store in localStorage to persist across refreshes
      localStorage.setItem("demo_authenticated", "true");
      setIsPasscodeAuthenticated(true);
      navigate("/");
    } else {
      setError("Invalid passcode. Please try again.");
    }
  };

  return (
    <Box mih="100vh" bg="white" pos="relative" py="xl">
      <Container size="xs">
        <Stack align="center" gap="xl">
          <Image
            w={300}
            src={Logo_dark}
            alt="AffiliateFormula Logo"
            fit="contain"
          />

          <Paper p="xl" radius="md" withBorder shadow="md" w="100%">
            <Title order={2} ta="center" mb="lg">
              Demo Access
            </Title>
            <Text c="dimmed" size="sm" ta="center" mb="xl">
              This application is in demo mode. Please enter the passcode to
              continue.
            </Text>

            <form onSubmit={handleSubmit}>
              <Stack>
                <TextInput
                  required
                  label="Passcode"
                  placeholder="Enter demo passcode"
                  value={passcode}
                  onChange={(e) => setPasscode(e.target.value)}
                  error={error}
                />

                <Button type="submit" color="orange" fullWidth mt="md">
                  Access Demo
                </Button>
              </Stack>
            </form>
          </Paper>
        </Stack>
      </Container>
    </Box>
  );
};

export default PasscodePage;
