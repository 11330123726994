import {
  Anchor,
  Box,
  Container,
  Divider,
  Flex,
  Group,
  Image,
  Stack,
  Text,
} from "@mantine/core";
import {
  IconBrandInstagram,
  IconBrandTwitter,
  IconBrandYoutube,
  IconMail,
} from "@tabler/icons-react";
import React from "react";
import { Link } from "react-router-dom";
import fullLogo from "../assets/full_logo2.svg";

const Footer: React.FC = () => {
  const currentYear = new Date().getFullYear();

  return (
    <Box component="footer" py="xl" bg="gray.1">
      <Container size="xl">
        <Flex
          direction={{ base: "column", md: "row" }}
          justify="space-between"
          align={{ base: "center", md: "flex-start" }}
          gap="xl"
        >
          <Stack gap="md" align={"center"}>
            <Image src={fullLogo} alt="Collabish Logo" fit="contain" />
            <Text
              size="sm"
              c="gray.7"
              maw={300}
              // ta={{ base: "center", md: "left" }}
              ta={"center"}
            >
              Empowering creators and brands to build successful partnerships
            </Text>
          </Stack>

          <Stack gap="md" align={"center"}>
            <Text fw={600} size="sm">
              Company
            </Text>
            {/* <Anchor component={Link} to="/about" size="sm" c="gray.7">
              About Us
            </Anchor> */}
            <Anchor component={Link} to="/contact" size="sm" c="gray.7">
              Contact
            </Anchor>
            {/* <Anchor component={Link} to="/faq" size="sm" c="gray.7">
              FAQ
            </Anchor> */}
          </Stack>

          <Stack gap="md" align={"center"}>
            <Text fw={600} size="sm">
              Legal
            </Text>
            <Anchor component={Link} to="/terms" size="sm" c="gray.7">
              Terms of Service
            </Anchor>
            <Anchor component={Link} to="/privacy" size="sm" c="gray.7">
              Privacy Policy
            </Anchor>
            {/* <Anchor component={Link} to="/cookies" size="sm" c="gray.7">
              Cookie Policy
            </Anchor> */}
          </Stack>

          <Stack gap="md" align={"center"}>
            <Text fw={600} size="sm">
              Connect With Us
            </Text>
            <Group gap="md">
              <Anchor
                href="https://twitter.com/affiliateformula"
                target="_blank"
                c="brandOrange.6"
              >
                <IconBrandTwitter size={20} />
              </Anchor>
              <Anchor
                href="https://instagram.com/affiliateformula"
                target="_blank"
                c="brandOrange.6"
              >
                <IconBrandInstagram size={20} />
              </Anchor>
              <Anchor
                href="https://youtube.com/affiliateformula"
                target="_blank"
                c="brandOrange.6"
              >
                <IconBrandYoutube size={20} />
              </Anchor>
              <Anchor
                href="mailto:support@affiliateformula.com"
                c="brandOrange.6"
              >
                <IconMail size={20} />
              </Anchor>
            </Group>
          </Stack>
        </Flex>

        <Divider my="lg" />

        <Flex
          justify="space-between"
          align="center"
          direction={{ base: "column", sm: "row" }}
          gap={{ base: "xs", sm: 0 }}
        >
          <Text size="xs" c="gray.6">
            © {currentYear} Collabish. All rights reserved.
          </Text>
          <Text size="xs" c="gray.6">
            Made with ❤️ for creators and brands
          </Text>
        </Flex>
      </Container>
    </Box>
  );
};

export default Footer;
