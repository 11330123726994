import {
  Box,
  Button,
  Container,
  Flex,
  Image,
  Paper,
  SimpleGrid,
  Stack,
  Text,
  ThemeIcon,
  Title,
  rem,
} from "@mantine/core";
import {
  IconBriefcase,
  IconRobot,
  IconRocket,
  IconSettings,
  IconUser,
} from "@tabler/icons-react";
import React from "react";
import { Link } from "react-router-dom";
import Logo_dark from "../../assets/full_logo2.svg";
import "./LandingPage.scss";

interface UserTypeCardProps {
  icon: React.ReactNode;
  title: string;
  description: string;
  path: string;
}

const UserTypeCard: React.FC<UserTypeCardProps> = ({
  icon,
  title,
  description,
  path,
}) => {
  return (
    <Link
      to={path}
      style={{ textDecoration: "none", width: "100%", height: "100%" }}
    >
      <Paper
        p="xl"
        radius="lg"
        shadow="md"
        withBorder
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
          borderLeft: "4px solid var(--mantine-color-brandOrange-6)",
          transition: "transform 0.3s ease, box-shadow 0.3s ease",
          cursor: "pointer",
          position: "relative",
          overflow: "hidden",
        }}
        className="user-type-card"
      >
        <Stack align="center" gap="lg">
          <ThemeIcon
            size={rem(80)}
            radius="md"
            color="orange"
            variant="transparent"
          >
            {icon}
          </ThemeIcon>

          <Title order={2} ta="center">
            {title}
          </Title>

          <Text ta="center" c="dimmed" size="md">
            {description}
          </Text>
        </Stack>

        <Button color="orange" size="md" radius="md" mt="lg" fullWidth>
          Enter as {title}
        </Button>
      </Paper>
    </Link>
  );
};

interface FeatureProps {
  icon: React.ReactNode;
  title: string;
  description: string;
}

const Feature: React.FC<FeatureProps> = ({ icon, title, description }) => {
  return (
    <Paper
      p="lg"
      radius="lg"
      shadow="md"
      withBorder
      style={(theme) => ({
        borderLeft: `4px solid ${theme.colors.brandOrange[6]}`,
        transition: "all 0.3s",
        "&:hover": {
          transform: "translateY(-5px)",
          boxShadow: theme.shadows.xl,
        },
      })}
    >
      <Stack align="center" gap="md">
        <ThemeIcon
          size={rem(60)}
          radius="md"
          color="orange"
          variant="transparent"
        >
          {icon}
        </ThemeIcon>

        <Title order={3} ta="center">
          {title}
        </Title>

        <Text ta="center" c="dimmed">
          {description}
        </Text>
      </Stack>
    </Paper>
  );
};

const RoleSelectionPage: React.FC = () => {
  return (
    <Box mih="100vh" bg="white" pos="relative">
      <Box
        pos="absolute"
        top={0}
        left={0}
        right={0}
        h={300}
        style={(theme) => ({
          background: `linear-gradient(to bottom, ${theme.colors.brandYellow[6]}, white)`,
          opacity: 0.4,
          zIndex: 0,
        })}
      />

      <Container
        size="xl"
        pt={{ base: rem(80), md: rem(112) }}
        pos="relative"
        style={{ zIndex: 1 }}
      >
        <Flex direction="column" align="center" justify="center" ta="center">
          <Image
            w={800}
            h={300}
            src={Logo_dark}
            alt="AffiliateFormula Logo"
            fit="cover"
          />

          <Text size="xl" mt="md" mb="xl" c="dimmed" fw={500}>
            Which one are you?
          </Text>

          <SimpleGrid
            cols={{ base: 1, md: 2 }}
            spacing="xl"
            mt="xl"
            w="100%"
            maw={1000}
          >
            <UserTypeCard
              icon={<IconUser size={rem(40)} stroke={1.5} />}
              title="Creator"
              description="Collab with brands to earn commissions by promoting products."
              path="/creator-ai-assistant"
            />
            <UserTypeCard
              icon={<IconBriefcase size={rem(40)} stroke={1.5} />}
              title="Brand"
              description="Connect with influential creators to promote your products to even more eyes."
              path="/brand-dashboard"
            />
          </SimpleGrid>

          <Title order={2} size="h1" mt={80} mb="xl" fw={700}>
            Why Choose Collabish?
          </Title>

          <SimpleGrid cols={{ base: 1, md: 3 }} spacing="xl" mt="xl" w="100%">
            <Feature
              icon={<IconRocket size={rem(32)} stroke={1.5} />}
              title="TikTok Marketing Expertise"
              description="Products made from successful TikTok experts."
            />
            <Feature
              icon={<IconRobot size={rem(32)} stroke={1.5} />}
              title="AI Powered Tooling"
              description="Leverage our AI-powered tools for enhanced content creation."
            />
            <Feature
              icon={<IconSettings size={rem(32)} stroke={1.5} />}
              title="Much More to Come"
              description="From trending product analysis to advanced analytics, we're just getting started."
            />
          </SimpleGrid>
        </Flex>
      </Container>

      <Box
        pos="absolute"
        bottom={0}
        left={0}
        right={0}
        h={200}
        style={(theme) => ({
          background: `linear-gradient(to top, ${theme.colors.brandGreen[6]}, transparent)`,
          opacity: 0.1,
          zIndex: 0,
        })}
      />
    </Box>
  );
};

export default RoleSelectionPage;
