export const privacyPolicy = `Last Updated: 9/28/2024

Collabish ("we", "our", or "us") is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website affiliateformula.io (the "Website") or use our AI prompt generation service for TikTok videos (the "Service").

Please read this privacy policy carefully. If you do not agree with the terms of this privacy policy, please do not access the Website or use our Service.

## 1. Information We Collect

We collect information that you provide directly to us when you use our Service:

1.1. Personal Information:
- Name
- Email address
- Password (encrypted)
- Optional self-declared personal information

1.2. User Content:
- AI prompt requests
- Any other information you choose to provide

## 2. How We Use Your Information

We use the information we collect for various purposes, including to:

2.1. Provide, maintain, and improve our Service
2.2. Process and complete transactions
2.3. Send you technical notices, updates, security alerts, and support messages
2.4. Respond to your comments, questions, and requests
2.5. Personalize and improve your experience
2.6. Monitor and analyze trends, usage, and activities in connection with our Service
2.7. Detect, investigate, and prevent errors and issues
2.8. Comply with legal obligations

## 3. Sharing of Your Information

We may share your information in the following situations:

3.1. With your consent
3.2. To comply with legal obligations
3.3. To protect and defend our rights and property
3.4. With service providers who help us provide and improve our Service
3.5. In connection with a business transaction such as a merger, acquisition, or sale of assets

## 4. Data Security

We implement appropriate technical and organizational measures to maintain the safety of your personal information. However, no method of transmission over the Internet or electronic storage is 100% secure, so we cannot guarantee absolute security.

## 5. Your Data Protection Rights

Depending on your location, you may have certain rights regarding your personal information, such as:

5.1. The right to access
5.2. The right to rectification
5.3. The right to erasure
5.4. The right to restrict processing
5.5. The right to data portability
5.6. The right to object

To exercise these rights, please contact us using the information provided in the "Contact Us" section.

## 6. Children's Privacy

Our Service is not intended for children under the age of 13. We do not knowingly collect personal information from children under 13. If you are a parent or guardian and believe your child has provided us with personal information, please contact us.

## 7. Changes to This Privacy Policy

We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Last Updated" date.

## 8. Third-Party Links

Our Website may contain links to third-party websites. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.

## 9. Cookies and Tracking Technologies

We use cookies and similar tracking technologies to track activity on our Service and hold certain information. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent.

## 10. International Data Transfers

Your information may be transferred to — and maintained on — computers located outside of your state, province, country, or other governmental jurisdiction where the data protection laws may differ from those of your jurisdiction.

## 11. Contact Us

If you have any questions about this Privacy Policy, please contact us at:

Collabish
Email: affiliateformula.tech@gmail.com

By using our Service, you acknowledge that you have read and understood this Privacy Policy and agree to its terms.`;

const potentialThingsToCollect = `
1.3. Automatically Collected Information:
- IP address
- Browser type
- Device information
- Operating system
- Date and time of visits
- Pages viewed
- Time spent on pages
- Referral URLs
`;
