export const termsOfService = `Last Updated: 9/28/2024

Please read these Terms of Service ("Terms", "ToS") carefully before using affiliateformula.io (the "Service") operated by Collabish ("us", "we", or "our").

## 1. Acceptance of Terms
By accessing or using the Service, you agree to be bound by these Terms. If you disagree with any part of the terms, you may not access the Service.

## 2. Description of Service
affiliateformula.io provides AI-generated prompts to assist TikTok users in creating videos for product promotion. The Service is for informational and creative purposes only.

## 3. User Accounts
3.1. You may be required to create an account to use certain features of the Service. You are responsible for maintaining the confidentiality of your account and password.

3.2. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete.

## 4. User Content
4.1. You retain all rights to any content you submit, post, or display on or through the Service ("User Content").

4.2. By posting User Content, you grant us a worldwide, non-exclusive, royalty-free license to use, reproduce, modify, adapt, publish, translate, and distribute your User Content in any existing or future media.

## 5. Intellectual Property
5.1. The Service and its original content (excluding User Content), features, and functionality are and will remain the exclusive property of Collabish and its licensors.

5.2. The AI-generated prompts are provided for your personal use. You may not reproduce, distribute, or sell these prompts without our explicit permission.

## 6. Prohibited Uses
You agree not to use the Service:

6.1. In any way that violates any applicable national, federal, state, local, or international law or regulation.

6.2. To impersonate or attempt to impersonate the Company, a Company employee, another user, or any other person or entity.

6.3. To engage in any other conduct that restricts or inhibits anyone's use or enjoyment of the Service, or which, as determined by us, may harm the Company or users of the Service or expose them to liability.

## 7. Disclaimer
7.1. The Service is provided on an "AS IS" and "AS AVAILABLE" basis. We do not warrant that the results provided through the use of the service will be accurate, reliable, or meet your requirements.

7.2. We are not responsible for any videos or content created using our AI-generated prompts. Users are solely responsible for ensuring their content complies with TikTok's terms of service and any applicable laws or regulations.

## 8. Limitation of Liability
In no event shall Collabish, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from your access to or use of or inability to access or use the Service.

## 9. Changes to Terms
We reserve the right, at our sole discretion, to modify or replace these Terms at any time. We will provide notice of any significant changes by posting the new Terms on this page.

## 10. Contact Us
If you have any questions about these Terms, please contact us at affilateformula.tech@gmail.com.

By using the Service, you acknowledge that you have read and understood these Terms and agree to be bound by them.`;
